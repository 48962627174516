.wrapper-page-small {
  @extend .p-l-lg, .p-r-lg;
  margin: $lg auto;
  max-width: 700px;
  box-sizing: border-box;
}

.wrapper-page-large {
  margin: $lg auto;
  width: 1000px;
}

.wrapper-section {
  @extend .m-b-lg;
}
