// element-specific typography

h1 {
  @extend .m-none;
  font-family: $IBMPlexMonoRegular;
  font-size: $title;
  line-height: $titleLineHeight;
  font-weight: normal;
}

p {
  @extend .m-none;
  font-size: $contentMed;
  line-height: $contentMedLineHeight;
}

// change fonts
.ibm-plex-sans {
  font-family: $IBMPlexSansRegular;
}

.ibm-plex-sans-med {
  font-family: $IBMPlexSansMedium;
}

.ibm-plex-mono {
  font-family: $IBMPlexMonoRegular;
}

// change font-size

.font-content {
  font-size: $content;
  line-height: $contentLineHeight;
}

.font-content-lg {
  font-size: $contentLarge;
  line-height: $contentLargeLineHeight;
}

.alert {
  color: $alert;
}
