// Note, in order to position the marker at the correct location
// position: absolute
// left: -WIDTH/2
// top: -HEIGHT
.map-marker {
  position: absolute;
  left: -25px;
  top: -50px;

  img {
    height: 50px;
    width: 50px;
  }
}
