@font-face {
  font-family: 'ibm-plex-mono-regular';
  src: url('../../fonts/IBMPlexMono-Regular.woff2') format('woff2'),
    url('../../fonts/IBMPlexMono-Regular.woff') format('woff'),
    url('../../fonts/IBMPlexMono-Regular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ibm-plex-sans-regular';
  src: url('../../fonts/IBMPlexSans-Regular.woff2') format('woff2'),
    url('../../fonts/IBMPlexSans-Regular.woff') format('woff'),
    url('../../fonts/IBMPlexSans-Regular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ibm-plex-sans-medium';
  src: url('../../fonts/IBMPlexSans-Medium.woff2') format('woff2'),
    url('../../fonts/IBMPlexSans-Medium.woff') format('woff'),
    url('../../fonts/IBMPlexSans-Medium.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

$IBMPlexMonoRegular: 'ibm-plex-mono-regular', 'Lucida Console', Monaco,
  monospace;

$IBMPlexSansRegular: 'ibm-plex-sans-regular', 'Arial Narrow', sans-serif;
$IBMPlexSansMedium: 'ibm-plex-sans-medium', 'Arial Narrow', sans-serif;
