.border-none {
  border: none;
}

.border-bottom {
  border-bottom: 1px solid $secondary;
}

.border-red {
  border: 1px solid $alert;
  border-radius: 4px;
}
