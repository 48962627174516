.osano-cm-dialog *,
.osano-cm-info * {
  font-family: "ibm-plex-sans-regular", "Arial Narrow", sans-serif;
  font-size: 1rem;
}
.osano-cm-dialog {
  max-width: 500px !important;
  border: 1px solid #00819D;
  border-radius: 4px;
}
.osano-cm-message {
  margin-bottom: 6px;
}
.osano-cm-link {
  font-weight: 600;
  color: #00819D;
  text-decoration: none;
  padding: 10px 0;
  text-transform: lowercase;
  transition: color .25s ease;
}
.osano-cm-storage-policy::before {
  content: 'To learn more, visit our ';
  top: 0;
  left: 0;
  font-weight: 400;
  color: #282828;
}
.osano-cm-storage-policy::after {
  content: '.';
  top: 0;
  right: 0;
  font-weight: 400;
  color: #282828;
}
.osano-cm-link::first-letter {
  text-transform: uppercase;
}
.osano-cm-link:hover {
  color: #00404E;
}
.osano-cm-dialog__buttons {
  display: block;
}
.osano-cm-button {
  text-transform: lowercase;
  padding: 12px 16px;
  border: 1px solid #00819D;
  margin-bottom: 8px;
  min-width: 200px;
}
.osano-cm-button::first-letter {
  text-transform: uppercase;
}
.osano-cm-button.osano-cm-button--type_manage {
  background-color: #FFFFFF;
  color: #00819D;
}
.osano-cm-info {
  max-width: 30em;
}
.osano-cm-header {
  font-weight: 600;
  font-size: 1.35rem;
  background: #FFFFFF;
}
.osano-cm-description:last-of-type {
  margin-top: 6px; 
  margin-bottom: 24px;
}
.osano-cm-label {
  font-weight: 600;
  font-size: 1.125rem !important;
}
.osano-cm-label::first-letter {
  text-transform: uppercase;
}
.osano-cm-drawer-item {
  padding-top: 0;
}
.osano-cm-toggle {
  font-weight: 600;
  text-transform: lowercase;
  transition: color .25s ease;
}
.osano-cm-toggle::first-letter {
  text-transform: uppercase;
}
.osano-cm-toggle:hover {
  color: #00404E;
}
.osano-cm-toggle__input + .osano-cm-toggle__switch, 
.osano-cm-toggle__input:focus + .osano-cm-toggle__switch, 
.osano-cm-toggle__input:hover + .osano-cm-toggle__switch {
  background-color: #B6B6B6;
}
.osano-cm-toggle__input + .osano-cm-toggle__switch::after, 
.osano-cm-toggle__input:focus + .osano-cm-toggle__switch::after, 
.osano-cm-toggle__input:hover + .osano-cm-toggle__switch::after {
  background-color: #FFFFFF;
}
.osano-cm-toggle__input:checked + .osano-cm-toggle__switch, 
.osano-cm-toggle__input:checked:focus + .osano-cm-toggle__switch, 
.osano-cm-toggle__input:checked:hover + .osano-cm-toggle__switch {
  background-color: #00819D;
}
.osano-cm-toggle__input:checked + .osano-cm-toggle__switch::after, 
.osano-cm-toggle__input:checked:focus + .osano-cm-toggle__switch::after, 
.osano-cm-toggle__input:checked:hover + .osano-cm-toggle__switch::after {
  background-color: #FFFFFF;
}
.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch, 
.osano-cm-toggle__input:disabled:checked:focus + .osano-cm-toggle__switch, 
.osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch {
  background-color: #5FA0AE;
}
.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch::after, 
.osano-cm-toggle__input:disabled:checked:focus + .osano-cm-toggle__switch::after, 
.osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch::after {
  background-color: #FFFFFF;
}
.osano-cm-view__button {
  width: auto;
}
.osano-cm-powered-by__link {
  display: none;
}
.osano-cm-info-dialog-header__close:focus {
  background-color: transparent;
  border: transparent;
}
.osano-cm-info-dialog-header__close svg {
  stroke: #282828;
  width: 14px;
  height: 14px;
}
.osano-cm-widget {
  display: none;
} 
