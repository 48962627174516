.flex-reverse {
  flex-direction: row-reverse;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}
