.button {
  @extend .border-none, .p-none, .ibm-plex-sans-med, .font-content;

  cursor: pointer;
  line-height: normal;

  &.button-primary {
    @extend .p-l-med, .p-r-med, .p-b-sm, .p-t-sm, .font-content-lg;

    background-color: $accent1;
    color: $surface;

    border-radius: 4px;

    &:hover {
      background-color: $accent2;
    }

    &.fixed {
      @extend .flex, .align-center, .justify-center, .p-none, .font-content;

      width: 145px;
      height: 40px;
    }
  }

  &.width-265 {
    width: 265px;
  }

  &.button-secondary {
    @extend .p-l-med, .p-r-med, .p-b-xs, .p-t-xs;

    background-color: transparent;
    color: $accent1;

    border-radius: 4px;
    border: 1px solid $accent1;

    &:hover {
      border: 1px solid $accent2;
      color: $accent2;
    }

    &.large {
      @extend .p-b-sm, .p-t-sm, .font-content-lg;
    }
  }
}

.button-unstyled {
  @extend .button;

  background-color: $surface;
  color: $accent1;

  &:hover {
    @extend .underline;
  }
}
