body,
html {
  @extend .m-none;

  background-color: $surface;

  font-size: $content;
  line-height: $contentLineHeight;
  font-family: $IBMPlexSansRegular;

  // consistently adjust text size across browsers
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow-y: scroll;
}
