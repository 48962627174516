// can @extend this to any class in .scss files or include it in classNames in .jsx files
// "m" indicates margin, "t" top, "b" bottom, "r" right, "l" left

.m-none {
  margin: 0;
}

.m-xs {
  margin: $xs;
}

.m-sm {
  margin: $sm;
}

.m-med {
  margin: $med;
}

.m-lg {
  margin: $lg;
}

.m-xl {
  margin: $xl;
}

.m-xxl {
  margin: $xxl;
}

.m-auto {
  margin: auto;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xs {
  margin-top: $xs;
}

.m-t-sm {
  margin-top: $sm;
}

.m-t-med {
  margin-top: $med;
}

.m-t-lg {
  margin-top: $lg;
}

.m-t-xl {
  margin-top: $xl;
}

.m-t-xxl {
  margin-top: $xxl;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: $xs;
}

.m-b-sm {
  margin-bottom: $sm;
}

.m-b-med {
  margin-bottom: $med;
}

.m-b-lg {
  margin-bottom: $lg;
}

.m-b-xl {
  margin-bottom: $xl;
}

.m-b-xxl {
  margin-bottom: $xxl;
}

.m-b-auto {
  margin-bottom: auto;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xs {
  margin-right: $xs;
}

.m-r-sm {
  margin-right: $sm;
}

.m-r-med {
  margin-right: $med;
}

.m-r-lg {
  margin-right: $lg;
}

.m-r-xl {
  margin-right: $xl;
}

.m-r-xxl {
  margin-right: $xxl;
}

.m-r-auto {
  margin-right: auto;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: $xs;
}

.m-l-sm {
  margin-left: $sm;
}

.m-l-med {
  margin-left: $med;
}

.m-l-lg {
  margin-left: $lg;
}

.m-l-xl {
  margin-left: $xl;
}

.m-l-xxl {
  margin-left: $xxl;
}

.m-l-auto {
  margin-left: auto;
}
