.show-small-screen {
  @include medium-up {
    display: none !important;
  }
}

.hide-small-screen {
  @include small-only {
    display: none !important;
  }
}

.show-medium-screen {
  @include large-only {
    display: none !important;
  }
}

.hide-medium-screen {
  @include medium-down {
    display: none !important;
  }
}
