ul {
  @extend .p-none, .m-none;
}

.list-item {
  list-style: none;

  &.list-item-m-sm {
    @extend .m-b-sm;

    &:last-of-type {
      @extend .m-none;
    }
  }
}
