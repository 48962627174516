.inline {
  display: inline-block;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}
