$content: 1rem;
$contentMed: 1.125rem;
$contentLarge: 1.25rem;

$title: 2rem;

$contentLineHeight: 1.5rem;
$contentMedLineHeight: 1.6875rem;
$contentLargeLineHeight: 1.875rem;

$titleLineHeight: 2.25rem;
