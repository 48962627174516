// can @extend this to any class in .scss files or include it in classNames in .jsx files
// "p" indicates padding, "t" top, "b" bottom, "r" right, "l" left

.p-none {
  padding: 0;
}

.p-xs {
  padding: $xs;
}

.p-sm {
  padding: $sm;
}

.p-med {
  padding: $med;
}

.p-lg {
  padding: $lg;
}

.p-xl {
  padding: $xl;
}

.p-xxl {
  padding: $xxl;
}

.p-t-none {
  padding-top: 0;
}

.p-t-xs {
  padding-top: $xs;
}

.p-t-sm {
  padding-top: $sm;
}

.p-t-med {
  padding-top: $med;
}

.p-t-lg {
  padding-top: $lg;
}

.p-t-xl {
  padding-top: $xl;
}

.p-t-xxl {
  padding-top: $xxl;
}

.p-b-none {
  padding-bottom: 0;
}

.p-b-xs {
  padding-bottom: $xs;
}

.p-b-sm {
  padding-bottom: $sm;
}

.p-b-med {
  padding-bottom: $med;
}

.p-b-lg {
  padding-bottom: $lg;
}

.p-b-xl {
  padding-bottom: $xl;
}

.p-b-xxl {
  padding-bottom: $xxl;
}

.p-r-none {
  padding-right: 0;
}

.p-r-xs {
  padding-right: $xs;
}

.p-r-sm {
  padding-right: $sm;
}

.p-r-med {
  padding-right: $med;
}

.p-r-lg {
  padding-right: $lg;
}

.p-r-xl {
  padding-right: $xl;
}

.p-r-xxl {
  padding-right: $xxl;
}

.p-l-none {
  padding-left: 0;
}

.p-l-xs {
  padding-left: $xs;
}

.p-l-sm {
  padding-left: $sm;
}

.p-l-med {
  padding-left: $med;
}

.p-l-lg {
  padding-left: $lg;
}

.p-l-xl {
  padding-left: $xl;
}

.p-l-xxl {
  padding-left: $xxl;
}
