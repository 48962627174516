$medium-breakpoint: 701px;
$large-breakpoint: 1004px;

@mixin small-only {
  @media (max-width: $medium-breakpoint - 1px) {
    @content;
  }
}
@mixin medium-only {
  @media (min-width: $medium-breakpoint) and (max-width: $large-breakpoint - 1px) {
    @content;
  }
}
@mixin large-only {
  @media (min-width: $large-breakpoint) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: $medium-breakpoint) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: $large-breakpoint - 1px) {
    @content;
  }
}
